import React, { useState, useEffect } from 'react';
import './VariablesPage.css';

// API URL
const API_URL = 'https://srishticooperativesociety.com/api/variables';

// Fetch all variables
const fetchVariables = async () => {
  const response = await fetch(API_URL);
  if (!response.ok) throw new Error('Failed to fetch variables');
  return response.json();
};

// Create a new variable
const createVariable = async (variable) => {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(variable),
  });
  if (!response.ok) throw new Error('Failed to create variable');
  return response.json();
};

// Update an existing variable
const updateVariable = async (variable_name, value) => {
  const response = await fetch(`${API_URL}/${variable_name}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ value }),
  });
  if (!response.ok) throw new Error('Failed to update variable');
  return response.json();
};

// Delete a variable
const deleteVariable = async (id) => {
  const response = await fetch(`${API_URL}/${id}`, {
    method: 'DELETE',
  });
  if (!response.ok) throw new Error('Failed to delete variable');
  return response.json();
};

const VariablesPage = () => {
  const [variables, setVariables] = useState([]);
  const [newVariable, setNewVariable] = useState({ variable_name: '', value: '' });
  const [editingVariable, setEditingVariable] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    const loadVariables = async () => {
      try {
        const data = await fetchVariables();
        setVariables(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    loadVariables();
  }, []);

  const handleCreate = async () => {
    if (!newVariable.variable_name || !newVariable.value) {
      setFormError('Please fill out both fields');
      return;
    }
    try {
      const createdVariable = await createVariable(newVariable);
      setVariables([...variables, createdVariable]);
      setNewVariable({ variable_name: '', value: '' });
      setFormError('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleUpdate = async (variable_name, value) => {
    if (!value) {
      setFormError('Value cannot be empty');
      return;
    }
    try {
      const updatedVariable = await updateVariable(variable_name, value);
      setVariables(variables.map(v => v.variable_name === variable_name ? updatedVariable : v));
      setEditingVariable(null);
      setFormError('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this variable?')) {
      try {
        await deleteVariable(id);
        setVariables(variables.filter(v => v.id !== id));
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleEdit = (variable) => {
    setEditingVariable(variable);
  };

  const handleCancelEdit = () => {
    setEditingVariable(null);
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="variables-page">
      <h2>Variables Management</h2>
      <table className="variables-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Variable Name</th>
            <th>Value</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {variables.map(variable => (
            <tr key={variable.id}>
              <td>{variable.id}</td>
              <td>{variable.variable_name}</td>
              <td>
                {editingVariable && editingVariable.variable_name === variable.variable_name ? (
                  <input
                    type="text"
                    defaultValue={editingVariable.value}
                    onChange={(e) => setEditingVariable({ ...editingVariable, value: e.target.value })}
                  />
                ) : (
                  variable.value
                )}
              </td>
              <td>
                {editingVariable && editingVariable.variable_name === variable.variable_name ? (
                  <>
                    <button onClick={() => handleUpdate(variable.variable_name, editingVariable.value)} className="save-button">Save</button>
                    <button onClick={handleCancelEdit} className="cancel-button">Cancel</button>
                  </>
                ) : (
                  <>
                    <button onClick={() => handleEdit(variable)} className="edit-button">Edit</button>
                    <button onClick={() => handleDelete(variable.id)} className="delete-button">Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3>Add New Variable</h3>
      <div className="new-variable-form">
        <input
          type="text"
          placeholder="Variable Name"
          value={newVariable.variable_name}
          onChange={(e) => setNewVariable({ ...newVariable, variable_name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Value"
          value={newVariable.value}
          onChange={(e) => setNewVariable({ ...newVariable, value: e.target.value })}
        />
        <button onClick={handleCreate} className="create-button">Create</button>
        {formError && <div className="form-error">{formError}</div>}
      </div>
    </div>
  );
};

export default VariablesPage;
