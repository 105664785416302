import React, { useState, useEffect } from 'react';
import './LoanRequests.css';

const LoanRequests = () => {
  const [loanRequests, setLoanRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [collectorNames, setCollectorNames] = useState({});
  const [formData, setFormData] = useState({
    loan_type: '',
    interest_rate: '',
    duration: '',
    amount: ''
  });

  useEffect(() => {
    const fetchLoanRequests = async () => {
      try {
        const response = await fetch('https://srishticooperativesociety.com/api/loan_requests');
        if (!response.ok) {
          throw new Error('Failed to fetch loan requests');
        }
        const data = await response.json();

        // Fetch collector details for each loan request
        const collectorData = await Promise.all(
          data.map(async (request) => {
            if (request.collector_assigned) {
              const collectorResponse = await fetch(`https://srishticooperativesociety.com/api/admins/${request.collector_assigned}`);
              const collector = await collectorResponse.json();
              return { id: request.collector_assigned, name: collector.name };
            }
            return null;
          })
        );

        const collectorNameMap = collectorData.reduce((acc, collector) => {
          if (collector) {
            acc[collector.id] = collector.name;
          }
          return acc;
        }, {});

        setCollectorNames(collectorNameMap);
        setLoanRequests(data);
        setFilteredRequests(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchLoanRequests();
  }, []);

  useEffect(() => {
    const fetchFilteredRequests = async () => {
      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests?search=${searchQuery}`);
        if (!response.ok) {
          throw new Error('Failed to fetch filtered loan requests');
        }
        const data = await response.json();

        const searchQueryLower = searchQuery.toLowerCase();
        const finalFilteredRequests = data.filter(request =>
          request.firstname.toLowerCase().includes(searchQueryLower) ||
          request.lastname.toLowerCase().includes(searchQueryLower) ||
          request.id.toString().includes(searchQueryLower)
        );

        setFilteredRequests(finalFilteredRequests);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchFilteredRequests();
  }, [searchQuery]);

  const handleToggleApproval = async (id, currentStatus) => {
    const newStatus = currentStatus === 'approved' ? 'rejected' : 'approved'; // Toggle between 'approved' and 'rejected'
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ approval_status: newStatus }),
      });

      if (!response.ok) {
        throw new Error(`Failed to ${newStatus === 'approved' ? 'approve' : 'reject'} loan request`);
      }

      const updatedLoanRequests = filteredRequests.map(request =>
        request.id === id ? { ...request, approval_status: newStatus } : request
      );
      setFilteredRequests(updatedLoanRequests);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEdit = (request) => {
    setSelectedRequest(request);
    setFormData({
      loan_type: request.loan_type,
      interest_rate: request.interest_rate,
      duration: request.duration,
      amount: request.amount
    });
    setPopupVisible(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this loan request?')) {
      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${id}`, {
          method: 'DELETE',
        });

        if (!response.ok) {
          throw new Error('Failed to delete loan request');
        }

        const updatedLoanRequests = filteredRequests.filter(request => request.id !== id);
        setFilteredRequests(updatedLoanRequests);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setSelectedRequest(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${selectedRequest.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to update loan request');
      }

      const updatedLoanRequests = filteredRequests.map(request =>
        request.id === selectedRequest.id ? { ...request, ...formData } : request
      );
      setFilteredRequests(updatedLoanRequests);
      handleClosePopup();
    } catch (error) {
      setError(error.message);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  return (
    <div className="loan-requests-page">
      <h2>All Loan Requests</h2>
      {error && <p className="error-message">{error}</p>}

      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by firstname, lastname, or loan ID"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <table className="loan-requests-table">
        <thead>
          <tr>
            <th>Serial No.</th>
            <th>FirstName</th>
            <th>LastName</th>
            <th>LoanID</th>
            <th>Type</th>
            <th>Interest Rate</th>
            <th>Duration</th>
            <th>Amount</th>
         
            <th>Status</th>
            <th>Posted At</th>
            <th>FieldOfficer</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredRequests.map((request, index) => (
            <tr key={request.id}>
              <td>{index + 1}</td>
              <td>{request.firstname}</td>
              <td>{request.lastname}</td>
              <td>{request.id}</td>
              <td>{request.loan_type}</td>
              <td>{request.interest_rate}%</td>
              <td>{request.duration} months</td>
              <td>₹{request.amount}</td>
            
              <td>{request.approval_status}</td>
              <td>{formatDate(request.posted_at).toLocaleString()}</td>
              <td>{collectorNames[request.collector_assigned] || 'Not Assigned'}</td>
              <td>
                <>
                  <button onClick={() => handleToggleApproval(request.id, request.approval_status)}>
                    {request.approval_status === 'approved' ? 'Reject' : 'Approve'}
                  </button>
                  <button className='edit-btn' onClick={() => handleEdit(request)}>Edit</button>
                  <button className='delete-btn' onClick={() => handleDelete(request.id)}>Delete</button>
                </>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {popupVisible && selectedRequest && (
        <div className="popup">
          <div className="popup-content">
            <h3>Edit Loan Request</h3>
            <form onSubmit={handleUpdate}>
              <div>
                <label>Loan Type:</label>
                <input
                  type="text"
                  name="loan_type"
                  value={formData.loan_type}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Interest Rate:</label>
                <input
                  type="number"
                  name="interest_rate"
                  value={formData.interest_rate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Duration (Months):</label>
                <input
                  type="number"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Amount:</label>
                <input
                  type="number"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit">Update</button>
              <button type="button" onClick={handleClosePopup}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanRequests;
