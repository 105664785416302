import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './DownloadForm.css';

const DownloadForm = () => {
  const { id } = useParams();
  const [loan, setLoan] = useState(null);
  const [installments, setInstallments] = useState([]);

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${id}`);
        const data = await response.json();
        setLoan(data);
        if (data.approval_status === 'approved') {
          await calculateInstallments(data);
        }
      } catch (error) {
        console.error('Error fetching loan details:', error);
      }
    };

    fetchLoanDetails();
  }, [id]);

  const fetchCollections = async (loanId) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/collections/loan/${loanId}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching collections:', error);
      return [];
    }
  };

  const calculateInstallments = async (loan) => {
    const { id, duration, amount, interest_rate, updated_at, installment_type } = loan;
    const collectionsData = await fetchCollections(id);

    const installments = [];
    const startDate = new Date(updated_at);

    if (installment_type === 'monthly') {
      const monthlyInterestRate = interest_rate / 100 / 12;
      const monthlyInstallment = (amount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -duration));

      for (let i = 0; i < duration; i++) {
        const installmentDate = new Date(startDate);
        installmentDate.setMonth(startDate.getMonth() + i);
        const installmentDateString = installmentDate.toLocaleDateString();
        const collectionOnDate = collectionsData.find(collection => new Date(collection.collection_date).toLocaleDateString() === installmentDateString);

        const status = collectionOnDate ? 'Submitted' : 'Pending';
        const today = new Date();
        const isOverdue = new Date(installmentDate) < today && status === 'Pending';

        installments.push({
          date: installmentDateString,
          amount: monthlyInstallment.toFixed(2),
          status: isOverdue ? 'Overdue' : status
        });
      }
    } else if (installment_type === 'daily') {
      const totalDays = duration * 30;
      const dailyInterestRate = interest_rate / 100 / 365;
      const dailyInstallment = (amount * dailyInterestRate) / (1 - Math.pow(1 + dailyInterestRate, -totalDays));

      for (let i = 0; i < totalDays; i++) {
        const installmentDate = new Date(startDate);
        installmentDate.setDate(startDate.getDate() + i);
        const installmentDateString = installmentDate.toLocaleDateString();
        const collectionOnDate = collectionsData.find(collection => new Date(collection.collection_date).toLocaleDateString() === installmentDateString);

        const status = collectionOnDate ? 'Submitted' : 'Pending';
        const today = new Date();
        const isOverdue = new Date(installmentDate) < today && status === 'Pending';

        installments.push({
          date: installmentDateString,
          amount: dailyInstallment.toFixed(2),
          status: isOverdue ? 'Overdue' : status
        });
      }
    }

    setInstallments(installments);
  };

  const handlePrint = () => {
    window.print();
  };

  if (!loan) {
    return <div>Loading...</div>;
  }

  const aadharCardUrl = `https://srishticooperativesociety.com/storage/app/public/${loan.aadhar_card.replace('public/', '')}`;
  const profilePictureUrl = loan.profile_picture ? `https://srishticooperativesociety.com/storage/app/public/${loan.profile_picture.replace('public/', '')}` : null;

  return (
    <div className="download-form">
      <h1>Srishti Cooperative Society</h1>
      <h2>Loan Application Form | Loan ID: {loan.id}</h2>
      <div className="form-content">
        
        <div className="right-side">
          
          <ol>
            <li><strong>Loan Type:</strong> <span className="dotted-line">{loan.loan_type}</span></li>
            <li><strong>Duration:</strong> <span className="dotted-line">{loan.duration} months</span></li>
            <li><strong>Amount:</strong> <span className="dotted-line">₹{loan.amount}</span></li>
            <li><strong>First Name:</strong> <span className="dotted-line">{loan.firstname}</span></li>
            <li><strong>Last Name:</strong> <span className="dotted-line">{loan.lastname}</span></li>
            <li><strong>Address:</strong> <span className="dotted-line">{loan.address}</span></li>
            <li><strong>Pincode:</strong> <span className="dotted-line">{loan.pincode}</span></li>
            <li><strong>Installment Type:</strong> <span className="dotted-line">{loan.installment_type}</span></li>
            <li><strong>Guardian Name:</strong> <span className="dotted-line">{loan.guardian_name}</span></li>
            <li><strong>PAN No:</strong> <span className="dotted-line">{loan.pan_no}</span></li>
            <li><strong>Bank A/C No:</strong> <span className="dotted-line">{loan.bank_ac_no}</span></li>
            <li><strong>Nominee:</strong> <span className="dotted-line">{loan.nominiee}</span></li>
            <li><strong>Relation of Nominee:</strong> <span className="dotted-line">{loan.relation_of_nominiee}</span></li>
            <li><strong>Date of Birth:</strong> <span className="dotted-line">{loan.date_of_birth}</span></li>
            <li><strong>Approval Status:</strong> <span className="dotted-line">{loan.approval_status}</span></li>
            <li><strong>Posted At:</strong> <span className="dotted-line">{new Date(loan.posted_at).toLocaleDateString()}</span></li>
            <li>
              <img
                src={aadharCardUrl}
                alt="Aadhar Card"
                className="aadhar-card-image"
              />
            </li>
          </ol>
         
          <button onClick={handlePrint}>Print</button>
        </div>
        <div className="left-side">
          {profilePictureUrl && (
            <div className="profile-picture">
              <img src={profilePictureUrl} alt="Profile" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DownloadForm;
