import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import Navbar from './components/navbar/Navbar';
import Dashboard from './pages/dashboard/Dashboard';
import ApplyForLoan from './pages/applyforloan/ApplyForLoan';
import Disbursement from './pages/disbursement/Disbursement';
import LoginPage from './pages/auth/LoginPage';
import './App.css';
import Branches from './pages/branches/Branches';
import LoanRequests from './pages/loanrequests/LoanRequests';
import Collections from './pages/collections/Collections';
import ViewBranch from './pages/branches/ViewBranch';
import VariablesPage from './pages/variables/VariablesPage';
import DownloadForm from './pages/applyforloan/DownloadForm';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if Srishti_Admin_ID exists in localStorage
    const adminId = localStorage.getItem('Srishti_Admin_ID');
    if (adminId) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    setLoading(false); // Update the loading state after checking
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (loading) {
    return <div>Loading...</div>; // Optional: add a loader or spinner while checking auth status
  }

  return (
    <Router>
      <div className="App">
        {isLoggedIn && <Navbar toggleSidebar={toggleSidebar} />}
        <div className={`main-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
          {isLoggedIn && <Sidebar isSidebarOpen={isSidebarOpen} />}
          <div className="content">
            <Routes>
              {!isLoggedIn ? (
                <>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="*" element={<Navigate to="/login" />} />
                </>
              ) : (
                <>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/apply-for-loan" element={<ApplyForLoan />} />
                  <Route path="/variables" element={<VariablesPage />} />
                  <Route path="/viewbranch/:id" element={<ViewBranch />} />
                  <Route path="/disbursement" element={<Disbursement />} />
                  <Route path="/branches" element={<Branches />} />
                  <Route path="/loanrequests" element={<LoanRequests />} />
                  <Route path="/collections" element={<Collections />} />
                  {/* Default route */}
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="*" element={<Navigate to="/dashboard" />} />

                  <Route path="/download-form/:id" element={<DownloadForm />} /> {/* New Route */}
                </>
              )}
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
