import React, { useEffect, useState } from 'react';
import './Disbursement.css';

const Disbursement = () => {
  const [loanRequests, setLoanRequests] = useState([]);
  const [collectors, setCollectors] = useState({});
  const [branches, setBranches] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [filter, setFilter] = useState({
    firstname: '',
    lastname: '',
    date: '',
    branch: '',
    collector: ''
  });

  useEffect(() => {
    // Fetch loan requests
    fetch('https://srishticooperativesociety.com/api/loan_requests')
      .then(response => response.json())
      .then(data => {
        const approvedRequests = data.filter(req => req.approval_status === 'approved');
        setLoanRequests(approvedRequests);

        // Fetch collectors' details
        const collectorIds = [...new Set(approvedRequests.map(req => req.collector_assigned))];
        collectorIds.forEach(id => {
          fetch(`https://srishticooperativesociety.com/api/admins/${id}`)
            .then(response => response.json())
            .then(collectorData => {
              setCollectors(prev => ({ ...prev, [id]: collectorData.name }));
            });
        });
      });

    // Fetch branches
    fetch('https://srishticooperativesociety.com/api/branches')
      .then(response => response.json())
      .then(data => setBranches(data));
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  };

  const applyFilters = () => {
    let filtered = loanRequests;

    if (filter.firstname) {
      filtered = filtered.filter(req => req.firstname.toLowerCase().includes(filter.firstname.toLowerCase()));
    }

    if (filter.lastname) {
      filtered = filtered.filter(req => req.lastname.toLowerCase().includes(filter.lastname.toLowerCase()));
    }

    if (filter.date) {
      filtered = filtered.filter(req => new Date(req.posted_at).toLocaleDateString() === new Date(filter.date).toLocaleDateString());
    }

    if (filter.branch) {
      filtered = filtered.filter(req => req.branch_id === parseInt(filter.branch));
    }

    if (filter.collector) {
      filtered = filtered.filter(req => req.collector_assigned === parseInt(filter.collector));
    }

    setFilteredRequests(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [filter, loanRequests]);

  const totalAmount = filteredRequests.reduce((sum, req) => sum + parseFloat(req.amount), 0);

  return (
    <div className="disbursement-container">
      <h1>Loan Disbursement</h1>

      <div className="filters">
        <input 
          type="text" 
          placeholder="Filter by Firstname" 
          name="firstname" 
          value={filter.firstname} 
          onChange={handleFilterChange} 
        />
        <input 
          type="text" 
          placeholder="Filter by Lastname" 
          name="lastname" 
          value={filter.lastname} 
          onChange={handleFilterChange} 
        />
        <input 
          type="date" 
          name="date" 
          value={filter.date} 
          onChange={handleFilterChange} 
        />

        <select name="branch" value={filter.branch} onChange={handleFilterChange}>
          <option value="">Filter by Branch</option>
          {branches.map(branch => (
            <option key={branch.id} value={branch.id}>{branch.branchname}</option>
          ))}
        </select>

        <select name="collector" value={filter.collector} onChange={handleFilterChange}>
          <option value="">Filter by Collector</option>
          {Object.entries(collectors).map(([id, name]) => (
            <option key={id} value={id}>{name}</option>
          ))}
        </select>
      </div>

      <table className="disbursement-table">
        <thead>
          <tr>
            <th>Disbursement Amount</th>
            <th>To</th>
            <th>Branch</th>
            <th>Date</th>
            <th>FieldOfficer</th>
          </tr>
        </thead>
        <tbody>
          {filteredRequests.map(req => {
            const branch = branches.find(b => b.id === req.branch_id);
            return (
              <tr key={req.id}>
                <td>{req.amount}</td>
                <td>{req.firstname} {req.lastname}</td>
                <td>{branch ? branch.branchname : 'Loading...'}</td>
                <td>{new Date(req.posted_at).toLocaleDateString()}</td>
                <td>{collectors[req.collector_assigned] || 'Loading...'}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5" className="total-amount">
              <strong>Total Amount:</strong> ₹{totalAmount.toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Disbursement;
