import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const CACHE_KEY = 'dashboardData';
const CACHE_EXPIRY = 60000; // 1 minute

const Dashboard = () => {
  const [totalCollections, setTotalCollections] = useState(0);
  const [totalDisbursement, setTotalDisbursement] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalApprovedAmount, setTotalApprovedAmount] = useState(0);
  const [totalProjectedAmount, setTotalProjectedAmount] = useState(0);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        const collectionsResponse = await fetch('https://srishticooperativesociety.com/api/total_collections');
        const collectionsData = await collectionsResponse.json();
        setTotalCollections(parseFloat(collectionsData));

        await new Promise(resolve => setTimeout(resolve, 1800));

        const disbursementResponse = await fetch('https://srishticooperativesociety.com/api/total_disbursement');
        const disbursementData = await disbursementResponse.json();
        setTotalDisbursement(parseFloat(disbursementData));

        setTotalProfit(parseFloat(collectionsData) - parseFloat(disbursementData));

        await new Promise(resolve => setTimeout(resolve, 1800));

        const approvedLoanResponse = await fetch('https://srishticooperativesociety.com/api/total_approved_loan_amount');
        const approvedLoanData = await approvedLoanResponse.json();
        setTotalApprovedAmount(parseFloat(approvedLoanData.total_approved_amount));
        setTotalProjectedAmount(parseFloat(approvedLoanData.total_projected_amount));
      } catch (error) {
        console.error('Error fetching totals:', error);
      }
    };

    const fetchBranches = async () => {
      try {
        const response = await fetch('https://srishticooperativesociety.com/api/branches');
        const branchesData = await response.json();
        setBranches(branchesData);
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    const updateCacheAndFetch = async () => {
      setLoading(true);
      await fetchTotals();
      await fetchBranches();
      const dashboardData = {
        totalCollections,
        totalDisbursement,
        totalProfit,
        totalApprovedAmount,
        totalProjectedAmount,
        branches,
        timestamp: Date.now(),
      };
      localStorage.setItem(CACHE_KEY, JSON.stringify(dashboardData));
      setLoading(false);
    };

    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      const isCacheExpired = Date.now() - parsedData.timestamp > CACHE_EXPIRY;

      setTotalCollections(parsedData.totalCollections);
      setTotalDisbursement(parsedData.totalDisbursement);
      setTotalProfit(parsedData.totalProfit);
      setTotalApprovedAmount(parsedData.totalApprovedAmount);
      setTotalProjectedAmount(parsedData.totalProjectedAmount);
      setBranches(parsedData.branches);

      if (isCacheExpired) {
        updateCacheAndFetch();
      } else {
        const timerId = setTimeout(updateCacheAndFetch, CACHE_EXPIRY - (Date.now() - parsedData.timestamp));
        return () => clearTimeout(timerId);
      }
    } else {
      updateCacheAndFetch();
    }

    const intervalId = setInterval(updateCacheAndFetch, CACHE_EXPIRY);
    return () => clearInterval(intervalId);
  }, []);

  const handleBranchClick = (branchId) => {
    navigate(`/viewbranch/${branchId}`);
  };

  const randomColor = () => {
    const colors = ['#FFB6C1', '#FF7F50', '#FFD700', '#ADFF2F', '#87CEFA', '#DDA0DD', '#FF6347', '#40E0D0'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const formatNumber = (num) => {
    const numberString = num.toString().split('.');
    const lastThree = numberString[0].slice(-3);
    const otherNumbers = numberString[0].slice(0, -3);
    const formattedNumber = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastThree;
    return numberString.length > 1 ? formattedNumber + '.' + numberString[1] : formattedNumber;
  };
  

  return (
    <div className="dashboard-container">
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <h1>Dashboard</h1>
          <div className="cards">
            <div className="card">
              <h2>Total Collections</h2>
              <p>₹{formatNumber(totalCollections.toFixed(2))}</p>
             
            </div>
            <div className="card">
              <h2>Total Disbursement</h2>
              <p>₹{formatNumber(totalDisbursement.toFixed(2))}</p>
            </div>
            <div className="card">
              <h2>Total Profit(Present)</h2>
              <p>₹{formatNumber(totalProfit.toFixed(2))}</p>
            </div>
            <div className="card">
              <h2>Total Approved Loan Amount</h2>
              <p>₹{formatNumber(totalApprovedAmount.toFixed(2))}</p>
            </div>
            <div className="card">
              <h2>Total Amount After Interest</h2>
              <p>₹{formatNumber(totalProjectedAmount.toFixed(2))}</p>
            </div>

            <div className="card">
              <h2>Total Projected Profit (Future)</h2>
              <p>₹{formatNumber(totalProjectedAmount.toFixed(2)-totalApprovedAmount.toFixed(2))}</p>
            </div>

            <div className="card">
              <h2>Total Coverage Ratio</h2>
              <p>{totalCollections.toFixed(2)/totalDisbursement.toFixed(2)}</p>
            </div>

            <div className="card">
              <h2>Net Interest Margin</h2>
              <p>{100*(totalProjectedAmount.toFixed(2)-totalApprovedAmount.toFixed(2))/totalDisbursement.toFixed(2)}%</p>
            </div>

            <div className="card">
              <h2>Return on Assets (ROA)</h2>
              <p>{100*((totalProjectedAmount.toFixed(2)-totalApprovedAmount.toFixed(2))/totalApprovedAmount.toFixed(2))}%</p>
            </div>

            <div className="card">
              <h2>Expected Collection Efficiency</h2>
              <p>{(100*(totalCollections.toFixed(2)/totalProjectedAmount.toFixed(2))).toFixed(2)}%</p>
            </div>

            <div className="card">
              <h2>Loan Default Risk</h2>
              <p>{100*((totalDisbursement.toFixed(2)-totalCollections.toFixed(2))/totalDisbursement.toFixed(2))}%</p>
            </div>

          </div>
          <h2>Branches</h2>
          <div className="branches-container">
            {branches.map((branch) => (
              <div
                key={branch.id}
                className="branch-card"
                style={{ backgroundColor: randomColor() }}
                onClick={() => handleBranchClick(branch.id)}
              >
                <h3>{branch.branchname}</h3>
                <p>{branch.address}</p>
                <p>Branch Code: {branch.branchcode}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
