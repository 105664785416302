import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Branches.css';

const Branches = () => {
  const [branches, setBranches] = useState([]);
  const [formData, setFormData] = useState({
    branchname: '',
    address: '',
    branchcode: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await fetch('https://srishticooperativesociety.com/api/branches');
      if (!response.ok) {
        throw new Error('Failed to fetch branches');
      }
      const data = await response.json();
      setBranches(data);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let response;
      if (editMode) {
        response = await fetch(`https://srishticooperativesociety.com/api/branches/${editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
      } else {
        response = await fetch('https://srishticooperativesociety.com/api/branches', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save branch');
      }

      setFormData({ branchname: '', address: '', branchcode: '' });
      setEditMode(false);
      setEditId(null);
      fetchBranches(); // Refresh branches list after adding/updating
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEdit = (id, branchname, address, branchcode) => {
    setFormData({ branchname, address, branchcode });
    setEditMode(true);
    setEditId(id);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/branches/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete branch');
      }

      fetchBranches(); // Refresh branches list after deletion
    } catch (error) {
      setError(error.message);
    }
  };

  const handleView = (id) => {
    navigate(`/viewbranch/${id}`);
  };

  return (
    <div className="branches-page">
      <h2>Branches</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="branch-form">
        <div className="form-group">
          <label htmlFor="branchname">Branch Name:</label>
          <input
            type="text"
            id="branchname"
            name="branchname"
            value={formData.branchname}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="branchcode">Branch Code:</label>
          <input
            type="text"
            id="branchcode"
            name="branchcode"
            value={formData.branchcode}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn-add-edit">{editMode ? 'Update' : 'Add'}</button>
      </form>
      <div className="branches-list">
        <table>
          <thead>
            <tr>
              <th>Branch Name</th>
              <th>Address</th>
              <th>Branch Code</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {branches.map((branch) => (
              <tr key={branch.id}>
                <td>{branch.branchname}</td>
                <td>{branch.address}</td>
                <td>{branch.branchcode}</td>
                <td>
                  <button onClick={() => handleEdit(branch.id, branch.branchname, branch.address, branch.branchcode)}>Edit</button>
                  
                  <button onClick={() => handleView(branch.id)}>View Branch</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Branches;
