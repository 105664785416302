import React from 'react';
import { FaBars, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

function Navbar({ toggleSidebar }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('Srishti_Admin_ID'); // Remove Admin ID from localStorage
    localStorage.removeItem('Srishti_Branch_ID'); // Remove Branch ID from localStorage if needed
    navigate('/login'); // Redirect to login page
    window.location.reload(); // Refresh the page to clear state
  };

  return (
    <div className="navbar">
      <FaBars className="menu-icon" onClick={toggleSidebar} />
      <h1>Shristi Bank - Admin</h1>
      <div className="user-info">
        <span>Admin</span>
        <img
          src="https://cdn-icons-png.freepik.com/512/147/147142.png"
          alt="profile"
          className="profile-pic"
        />
        <button className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt /> Logout
        </button>
      </div>
    </div>
  );
}

export default Navbar;
